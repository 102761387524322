@import "../global.scss";
@import "../owl.scss";

section.page-home .content {
  background-image: linear-gradient(180deg,#020093 16%,#ffffff 90%)!important;
}
/*section.page-home .bottom-divider {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI1N3Z3IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTAgMHYxNDBoMTI4MEwwIDB6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 57vw;
  height: 57vw;
  z-index: 1;
  transform: scale(-1,1);
  margin-bottom: -1px;
  display: block;
  position: relative;
  pointer-events: none;
  width: 100%;
  left: 0;
  right: 0;
}*/
section.page-home .hero .left-box,section.page-home .hero .right-box {
  font-size: 1.5em;
  z-index: 2;
}
section.page-home .hero .left-box h3 {
  font-family: 'Heebo',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 300;
}
section.page-home .hero .left-box h3 {
  font-size: calc(1.525rem + 3.3vw);
}

@media (min-width: 1200px) {
  section.page-home .hero .left-box h3 {
    font-size: 4rem;
  }
}
section.page-home .hero .left-box h3 strong {
  color: $primary;
}


section.page-home .services .service {
  transition: transform 300ms ease 0ms;
  box-shadow: $shadow-dark;
  background-color: #ffffff;
  z-index: 2;

}
section.page-home .services .service:hover {
  transform: scaleX(1.07) scaleY(1.07)!important;
}
section.page-home .services .service h4 {
  font-weight:bolder;
  font-size: 1.25em;
}
section.page-home .learn-more-heating {
  background-image: $heating-grad;
}
section.page-home .learn-more-cooling {
  background-image: $cooling-grad;
}
section.page-home .learn-more {
  color: $white;
}
section.page-home .about {
  background-color: rgba(255,255,255,0.63);
  box-shadow: $shadow-dark;
}
section.page-home .about .left-box {
  color: $white;
  font-size: 1.5em;
}
section.page-home .about .left-box h3 {
  font-family: 'Heebo',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 300;
  font-size: 64px;
}
section.page-home .about .left-box h3 strong {
  font-weight: 600;
}
section.page-home .about .right-box {
  background-color: $white;
}
section.page-home .about .right-box h5 {
  font-size: 1.25em;
  font-weight: 600;
}
section.page-home .lazy-parallax {
  min-height: 500px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
section.page-home .testimonials {
  background-color: $white;
  box-shadow: $shadow-dark;
}
section.page-home .testimonials img.profile  {
  height:30px!important;
  width:30px!important;
}
section.page-home .testimonials h4 {
  font-weight:bolder;
  font-size:2em;
}
section.page-home .testimonials h4 {
  font-size:1.7em;
  font-family: 'Oswald', sans-serif;
  color: $primary;
}
