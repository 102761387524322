@import "bootstrap/scss/bootstrap";
$primary: #0079c2;
$dark: #3f3f3f;
$white: #fff;
$secondary: $dark;
$enable-negative-margins: true;
$darker: darken($white,40%);
$darkish: darken($white,30%);
$heating-grad: linear-gradient(148deg,#ffb565 0%,#ff8e60 100%);
$cooling-grad: linear-gradient(140deg, #45b2ff 0%, #0047d6 100%);
$shadow-blue: 0 26px 90px -14px #1a8aed;
$shadow-dark: 0px 26px 90px -14px rgb(0 71 214 / 38%);
$shadow-team: 6px 6px 18px 0px rgb(0 0 0 / 30%);
$yelp: #c41200;
// SIMS Styling


// HEADER

//  CTA
.yelp-color {
  color: $yelp;
}
header div.cta {
  color: $white;
  background-image: linear-gradient(to bottom right, #0079c2, #6990b5);
}
header div.cta a {
  color: $white;
  text-decoration: none;
}

//  Branding

#top-brand {
  background-color: $white;
  z-index:10000;
}

// Content Elements
.cooling-grad,.lazy-parallax {
  background-image: $cooling-grad;
}
.heating-grad {
  background-image: $heating-grad;
}
.estimate-button {
  background-image: $heating-grad;
  color: $white;
}

.btn-white {
  background: $white;
  color: $secondary;
  border: 2px $secondary solid!important;
}

section .action-button {
  background-image: $cooling-grad;
  color: $white;
}

// Global CTA

.global-cta {
  background-image: linear-gradient(180deg, #dbf0fc 0%, #ffffff 100%) !important;
}
.global-cta .start {

  font-size: 1.25em;
}
.global-cta .start i {
  color: $primary;
}
.global-cta .start h2 {
  color: $primary;
  font-size:3em;
  font-weight:600;
}

.global-cta .contact-simple {
  background-image: $cooling-grad;
  padding: 60px 30px;
  box-shadow: $shadow-blue;
}
.grecaptcha-badge { visibility: hidden; }
// FOOTER

footer .copy {
  font-size:1.2em;
  color: $darker;
}
footer .copy a {
  color: $darker;
}
footer .social a {
  font-size: 1.4em;
  color: $darker;
}
footer .marketing-2 img {
  max-height: 55px;
}
.bg-darkish {
  background-color: $darkish;
}
#accessibilityBar {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  z-index: 999999;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(100%,  -50%);
  background-color: rgba(34, 34, 34, .75);
  border-radius: .5rem 0 0 .5rem;
  box-shadow: 0 5px 30px rgba(34, 34, 34, 0);
}
#accessibilityBar * {
  box-sizing: border-box;
}
#accessibilityBar.active {
  transform: translate(0,  -50%);
  box-shadow: 0 5px 30px rgba(34, 34, 34, .5);
}
#accessibilityBar button {
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none;
  cursor: pointer;
}
#accessibilityBar button#universalAccessBtn {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  color: $white;
  background-color: $primary;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 50%;
  transform: translate(-110%,  -50%);
  cursor: pointer;
}
#accessibilityBar button#universalAccessBtn span {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  text-align: right;
  font-weight: bold;
  color: $white;
  background-color: $primary;
  box-shadow: 0 10px 30px rgba(102, 102, 102, .1);
  height: 100%;
  padding: 0 1rem;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 120%;
  transform: translateY(-50%);
}
#accessibilityBar button#universalAccessBtn.collapsed span {
  opacity: 0;
  right: 50%}
#accessibilityBar button#universalAccessBtn:hover span {
  opacity: 1;
  right: 120%}
#accessibilityBar button.setAccessibility {
  font-size: 12px;
  font-size: .75rem;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  color: #222;
  font-weight: 700;
  background-color: #eee;
  width: 100%;
  padding: .1rem .2rem;
  border-radius: .25rem;
  border: none;
  display: flex;
  align-items: center;
  margin: .25rem;
}
#accessibilityBar button.setAccessibility:hover {
  background-color: #fff;
}
#accessibilityBar button.setAccessibility strong {
  color: $white;
  background-color: $primary;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: .2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .25rem;
}
@media(max-width: 576px) {
  #accessibilityBar {
    position: fixed;
    top: initial;
    bottom: 0;
    transform: translateX(100%);
  }
  #accessibilityBar.active {
    transform: translateX(0);
  }
  #accessibilityBar #universalAccessBtn {
    top: initial;
    transform: translateX(-120%);
    bottom: 16px;
    border-radius: 50%}
}
body.contrast #accessibilityBar button[data-accessibility=contrast] {
  background-color: $primary;
}
body.darkmode #accessibilityBar button[data-accessibility=dark] {
  color: $white;
  background-color: $primary;
}
body.accessibility_readingLine #accessibilityBar button[data-accessibility=readingLine] {
  color: $white;
  background-color: $primary;
}
body.accessibility_markerLine #accessibilityBar button[data-accessibility=markerLine] {
  color: $white;
  background-color: $primary;
}
body.accessibility_readingLine #readingLine {
  background-color: $primary;
  opacity: 1;
  width: 100vw;
  height: .5rem;
  position: absolute;
  left: 0;
  z-index: 999999;
  transform: translateY(0.5rem);
}
body.accessibility_markerLine #markerLine {
  background-color: #e4fd00 !important;
  opacity: .75;
  border-width: .1px 0;
  border-style: solid;
  border-color: #cde400;
  width: 100vw;
  height: 1.75rem;
  position: absolute;
  left: 0;
  z-index: 999999;
  transform: translateY(-1rem);
  mix-blend-mode: color;
}
body.accessibility_markerLine.contrast #markerLine {
  mix-blend-mode: multiply;
  opacity: 1;
}
body.accessibility_markerLine.darkmode #markerLine {
  opacity: .25;
  background: #655b5b !important;
  mix-blend-mode: unset;
}
body.contrast {
  background: #000 !important;
  color: #fff !important;
}
body.contrast nav, body.contrast li, body.contrast ol, body.contrast header, body.contrast footer, body.contrast section, body.contrast main, body.contrast aside, body.contrast article {
  background: #000 !important;
  color: #fff !important;
}
body.contrast h1, body.contrast h2, body.contrast h3, body.contrast h4, body.contrast h5, body.contrast h6, body.contrast p, body.contrast label, body.contrast strong, body.contrast em, body.contrast cite, body.contrast q, body.contrast i, body.contrast b, body.contrast u, body.contrast span {
  color: #fff !important;
}
body.contrast a {
  color: #ff0 !important;
}
body.contrast a * {
  color: #ff0 !important;
}
body.contrast button, body.contrast input[type=button], body.contrast input[type=reset], body.contrast input[type=submit] {
  background: #ff0 !important;
  color: #000 !important;
  font-weight: bold;
  border: none !important;
}
body.contrast img {
  filter: grayscale(100%) contrast(120%);
}
body.contrast input[type=text], body.contrast input[type=password], body.contrast input[type=url], body.contrast input[type=search], body.contrast input[type=email], body.contrast input[type=tel], body.contrast input[type=date], body.contrast input[type=month], body.contrast input[type=week], body.contrast input[type=datetime], body.contrast input[type=datetime-local], body.contrast textarea, body.contrast input[type=number] {
  background: #000 !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}
body.contrast #accessibilityBar * {
  background-color: #000 !important;
  color: #ff0 !important;
}
body.contrast #accessibilityBar .setAccessibility {
  border: 1px solid !important;
}
body.contrast #accessibilityBar .setAccessibility[data-accessibility=contrast] {
  background-color: #ff0 !important;
  color: #000 !important;
}
body.darkmode {
  background: #121212 !important;
  color: #655b5b !important;
}
body.darkmode nav, body.darkmode li, body.darkmode ol, body.darkmode header, body.darkmode footer, body.darkmode section, body.darkmode main, body.darkmode aside, body.darkmode article {
  background: #121212 !important;
  color: #655b5b !important;
}
body.darkmode h1, body.darkmode h2, body.darkmode h3, body.darkmode h4, body.darkmode h5, body.darkmode h6, body.darkmode p, body.darkmode label, body.darkmode strong, body.darkmode em, body.darkmode cite, body.darkmode q, body.darkmode i, body.darkmode b, body.darkmode u, body.darkmode span {
  color: #655b5b !important;
}
body.darkmode a {
  color: #8d8080 !important;
}
body.darkmode a * {
  color: #8d8080 !important;
}
body.darkmode button, body.darkmode input[type=button], body.darkmode input[type=reset], body.darkmode input[type=submit] {
  background: #292323 !important;
  color: #655b5b !important;
  border: #191414 !important;
}
body.darkmode img {
  filter: grayscale(100%) contrast(120%);
}
body.darkmode input[type=text], body.darkmode input[type=password], body.darkmode input[type=url], body.darkmode input[type=search], body.darkmode input[type=email], body.darkmode input[type=tel], body.darkmode input[type=date], body.darkmode input[type=month], body.darkmode input[type=week], body.darkmode input[type=datetime], body.darkmode input[type=datetime-local], body.darkmode textarea, body.darkmode input[type=number] {
  background: #191414 !important;
  border: 1px solid #292323 !important;
  color: #655b5b !important;
}
body.darkmode img {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  filter: grayscale(75%) contrast(90%);
}
body.darkmode img:hover {
  filter: grayscale(0) contrast(100%);
}
body.darkmode #accessibilityBar * {
  background-color: #655b5b !important;
  color: #121212 !important;
}
body.darkmode #accessibilityBar .setAccessibility[data-accessibility=darkmode] {
  background-color: #121212 !important;
  color: #655b5b !important;
}
body.darkmode #accessibilityBar .setAccessibility {
  color: #8d8080 !important;
}
body.darkmode #accessibilityBar .setAccessibility * {
  color: #8d8080 !important;
}
body.darkmode #accessibilityBar .setAccessibility strong, body.darkmode #accessibilityBar .setAccessibility i {
  background-color: #191414 !important;
}
/*# sourceMappingURL=asb.css.map */
