
.owl-dots {
  text-align: center;
}
.owl-dots button.owl-dot.active span,
.owl-dots button.owl-dot:hover span {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 1px;
  left:1px;
}
.owl-dots button.owl-dot::before {
  border-radius: 50%;
  font-size: 1.25em;
  color: $yelp;
  content: '\f1e9';
  font-family: "FontAwesome";
}
.owl-dots button.owl-dot{
  border: 1px solid gray;
  background: white;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
  margin: 5px;
}
.owl-carousel .nav-btn{
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 100px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled{
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide{
  background: url('../image/nav-icon.png') no-repeat scroll 0 0;
  left: 30px;
  margin-top: 150px;
}
.owl-carousel .next-slide{
  background: url('../image/nav-icon.png') no-repeat scroll -24px 0px;
  right: 30px;
  margin-top: 150px;

}
.owl-carousel .prev-slide:hover{
  background-position: 0px -53px;
}
.owl-carousel .next-slide:hover{
  background-position: -24px -53px;
}
.owl-carousel {
  display:block;
}
span.img-text {
  text-decoration: none;
  outline: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  cursor: pointer;
  width: 100%;
  font-size: 23px;
  display: block;
  text-transform: capitalize;
}
span.img-text:hover {
  color: #2caae1;
}